/* 绘制提示语 */
export const MOUSE_TOOLTIP = {
  ADD_MARKER_START: '单击左键画点',
  ADD_LINE_START: '单击左键开始画线，esc取消',
  ADD_LINE_FIRST: '单击继续，esc取消',
  ADD_LINE_MORE: '单击继续，双击或enter键完成，esc取消',
  ADD_POLYGON_START: '单击左键开始画面，esc取消',
  ADD_POLYGON_FIRST: '单击左键继续画面',
  ADD_POLYGON_MORE: '单击继续，双击或enter键完成，esc取消',
  ADD_CIRCLE_START: '单击确定圆心',
  /*ADD_CIRCLE_FIRST: '鼠标移动绘制半径，单击完成，esc取消',*/
  ADD_CIRCLE_MORE: '鼠标移动绘制半径，单击完成，esc取消',
  ADD_RECTANGLE_START: '单击左键开始画面',
  /*ADD_RECTANGLE_FIRST: '鼠标移动绘制边长，单击完成，esc取消',*/
  ADD_RECTANGLE_MORE: '鼠标移动绘制边长，单击完成，esc取消',
  /*ADD_CIRCLEMARKER_START: '单击左键开始画线，esc取消',*/
  /*ADD_CIRCLEMARKER_FIRST: '单击继续，esc取消',*/
  /*ADD_CIRCLEMARKER_MORE: '单击继续，双击或enter键完成，esc取消',*/
  /*ADD_ROADPOLY_START: '单击左键开始画面，esc取消',*/
  /*ADD_ROADPOLY_FIRST: '单击左键继续画面',*/
  /*ADD_ROADPOLY_MORE: '单击继续，双击或enter键完成，esc取消',*/
  EDIT_MARKER: '点击拖动网点，Enter保存，Esc取消, Delete删除',
  EDIT_LINE: '点击拖动或添加节点，Enter保存，Esc取消, Delete删除',
  EDIT_POLYGON: '点击拖动或添加节点，Enter保存，Esc取消, Delete删除',
  EDIT_CIRCLE: '拖动圆心或调整半径，Enter保存，Esc取消, Delete删除',
  EDIT_RECTANGLE: '拖动矩形顶点调整大小，Enter保存，Esc取消, Delete删除',
  EDIT_UNKNOW: '点击拖动或添加节点, Enter保存，Esc取消, Delete删除',
  MOVE_MARKER: '点击拖动,调整位置，Enter保存，Esc取消',
  MOVE_LINE: '点击拖动,调整位置，Enter保存，Esc取消',
  MOVE_POLYGON: '点击拖动,调整位置，Enter保存，Esc取消',
  MOVE_CIRCLE: '点击拖动,调整位置，Enter保存，Esc取消',
  MOVE_RECTANGLE: '点击拖动,调整位置，Enter保存，Esc取消',
  MOVE_UNKNOW: '点击拖动,调整位置，Enter保存，Esc取消',
}

const eventConfig = {
  /*mouseup: {
    type: 2,
    trigger: 'mouseup',
    value: 'cancel' // 回调返回值
  },*/
  dblclick: {
    type: 'dblclick',
    trigger: 'dblclick',
    value: 'save' // 回调返回值
  },
  enter: {
    type: 13,
    trigger: 'keydown',
    value: 'save'
  },
  esc: {
    type: 27,
    trigger: 'keydown',
    value: 'cancel'
  },
  delete: {
    type: 46,
    trigger: 'keydown',
    value: 'delete'
  }
}
/* 键盘事件的配置 */
export const ADD_EVENT_TYPE = {
  ADD_MARKER_START: [
    eventConfig.esc,
    eventConfig.enter,
  ],
  ADD_LINE_START: [
    eventConfig.esc
  ],
  ADD_LINE_FIRST: [
    eventConfig.esc
  ],
  ADD_LINE_MORE: [
    eventConfig.dblclick,
    eventConfig.enter,
    eventConfig.esc
  ],
  ADD_CIRCLE_START: [
    eventConfig.esc
  ],
  /*ADD_CIRCLE_FIRST: [
    eventConfig.enter,
    eventConfig.esc
  ],*/
  ADD_CIRCLE_MORE: [
    eventConfig.enter,
    eventConfig.esc
  ],
  ADD_RECTANGLE_START: [
    eventConfig.esc
  ],
  /*ADD_RECTANGLE_FIRST: [
    eventConfig.enter,
    eventConfig.esc
  ],*/
  ADD_RECTANGLE_MORE: [
    eventConfig.enter,
    eventConfig.esc
  ],
  ADD_POLYGON_START: [
    eventConfig.esc
  ],
  ADD_POLYGON_FIRST: [
    eventConfig.esc
  ],
  ADD_POLYGON_MORE: [
    eventConfig.dblclick,
    eventConfig.enter,
    eventConfig.esc
  ],
  /*ADD_CIRCLEMARKER_START: [
    eventConfig.esc
  ],*/
  /*ADD_CIRCLEMARKER_FIRST: [
    eventConfig.esc
  ],*/
  /*ADD_CIRCLEMARKER_MORE: [
    eventConfig.dblclick,
    eventConfig.enter,
    eventConfig.esc
  ],*/
  /*ADD_ROADPOLY_START: [
    eventConfig.esc
  ],*/
  /*ADD_ROADPOLY_FIRST: [
    eventConfig.esc
  ],*/
  /*ADD_ROADPOLY_MORE: [
    eventConfig.dblclick,
    eventConfig.enter,
    eventConfig.esc
  ],*/
  /*ADD_POLYCIRCLE_START: [
    eventConfig.esc
  ],*/
  /*ADD_POLYCIRCLE_FIRST: [
    eventConfig.esc
  ],*/
  /*ADD_POLYCIRCLE_MORE: [
    eventConfig.dblclick,
    eventConfig.enter,
    eventConfig.esc
  ],*/
  EDIT_MARKER: [
    eventConfig.enter,
    eventConfig.esc,
    eventConfig.delete
  ],
  EDIT_LINE: [
    eventConfig.enter,
    eventConfig.esc,
    eventConfig.delete
  ],
  EDIT_POLYGON: [
    eventConfig.enter,
    eventConfig.esc,
    eventConfig.delete
  ],
  EDIT_CIRCLE: [
    eventConfig.enter,
    eventConfig.esc,
    eventConfig.delete
  ],
  EDIT_RECTANGLE: [
    eventConfig.enter,
    eventConfig.esc,
    eventConfig.delete
  ],
  EDIT_UNKNOW: [
    eventConfig.enter,
    eventConfig.esc,
    eventConfig.delete
  ],
  MOVE_MARKER: [
    eventConfig.enter,
    eventConfig.esc
  ],
  MOVE_LINE: [
    eventConfig.enter,
    eventConfig.esc
  ],
  MOVE_POLYGON: [
    eventConfig.enter,
    eventConfig.esc
  ],
  MOVE_CIRCLE: [
    eventConfig.enter,
    eventConfig.esc
  ],
  MOVE_RECTANGLE: [
    eventConfig.enter,
    eventConfig.esc
  ],
  MOVE_UNKNOW: [
    eventConfig.enter,
    eventConfig.esc
  ]
}
