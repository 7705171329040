import L,{ Polygon as LPolygon } from 'leaflet';
import { area } from '@turf/turf';
import { TransformFactory } from '../../utils/transformFactory';
// class Polygon {
//   constructor(latlng = [], options = {}) {
//     const polygon = L.polygon(latlng, options);
//     return polygon;
//   }
// }

class Polygon extends TransformFactory.usePolyline(LPolygon) {
  
  constructor(latlngs = [], options = {}) {
    super(latlngs, options)
  }

  getArea() {
    return area(this.toGeoJSON())
  }
 
}

export {Polygon}