import { getHttpRequest } from "./http";
export const includeJS = function (id, fileUrl, source) {
  if (source != null && !document.getElementById(id)) {
    var oHead = document.getElementsByTagName("HEAD").item(0);
    var oScript = document.createElement("script");
    oScript.type = "text/javascript";
    oScript.id = id;
    oScript.text = source;
    oHead.appendChild(oScript);
  }
};

export const requestJS = function (id = "", url) {
  if (!url) return;
  var httpRequest = getHttpRequest();
  httpRequest.onreadystatechange = function () {
    if (httpRequest.readyState === 4) {
      try {
        var oHead = document.getElementsByTagName("HEAD").item(0);
        var oScript = document.createElement("script");
        oScript.type = "text/javascript";
        oScript.id = id;
        oScript.text = httpRequest.responseText;
        oHead.insertBefore(oScript, oHead.children[0]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  httpRequest.open("GET", url, true); // 异步操作
  httpRequest.send(null);
};

export const maidian = function (type1, type2, extParams = {}) {
  let host = document.location.host;
  let blackList = ["localhost", "localhost:8080"]; // "jdmap.jdl.com", "jsapi-uat.jdl.com"
  if (blackList.indexOf(host) < 0) {
    setTimeout(() => {
      try {
        log(type1, type2, JSON.stringify(extParams));
      } catch (error) {
        console.log(error);
      }
    }, 500);
  }
};
