import { http } from "../../utils/http";
import { BASEURL, YUTUAPI } from "../../config";
import { isValidMapType, transLatLngs } from "../../utils/transform";
import { maidian } from "../../utils/dom.js";

export function AddressTrans(appKey) {
  maidian("sdk_service", "suggestion");
  return {
    addressSuggestion: ({
      region = "",
      keyword = "",
      from = "",
      to = "",
    } = {}) => {
      if (!region || !keyword ) {
        return new Promise((resolve, reject) => {
          reject(new Error("region、keyword参数不能为空"));
        });
      }
      return http({
        url: BASEURL + "addressTrans/addressSuggestion",
        method: "POST",
        data: [appKey, { region, keyword }],
      })
        .then((res) => {
          if (
            !(!isValidMapType(from) || !isValidMapType(to) || from === to) 
          ) {
            res.forEach((element) => {
              const transPosition = transLatLngs(
                [element.latitude, element.longitude],
                { from, to }
              );
              element.latitudeTrans = transPosition[0];
              element.longitudeTrans = transPosition[1];
            });
          }
          return res
        })
        .catch((err) => console.log(err));
    },
    suggestion: ({ region = "", keyword = "",
    from = "",
    to = "", } = {}) => {
      if (!keyword) {
        return new Promise((resolve, reject) => {
          reject(new Error("keyword参数不能为空"));
        });
      }
      return http({
        url: YUTUAPI + "/meta/suggestion?LOP-DN=meta.map.jd.com",
        method: "POST",
        data: { appKey, region, keyword },
      }).then( res => {
        if (
          !(!isValidMapType(from) || !isValidMapType(to) || from === to)
        ) {
          res.data.forEach((element) => {
            const transPosition = transLatLngs(
              [element.latitude, element.longitude],
              { from, to }
            );
            element.latitudeTrans = transPosition[0];
            element.longitudeTrans = transPosition[1];
          });
        }
        return res
      }).catch(e => console.log(e));
    },
  };
}
