import { http } from '../../utils/http';
import { BASEURL } from '../../config';
import {isValidMapType,transLatLngs} from '../../utils/transform'
import { maidian } from "../../utils/dom.js";

const BaseurlUat = 'https://api.jdwl.com/';
export function Route(appKey) {
  maidian("sdk_service", "route");
  return {
    getTruckRouts(truckRoutingRequestV2Dto = {}) {
      return http({
        url: BASEURL + '/getTruckRouts',
        method: 'POST',
        // data: [appKey, truckRoutingRequestV2Dto],
        data: [appKey, truckRoutingRequestV2Dto],
      });
    },
    // 沿路划线导航
    getRoadplan: ({ fromLocation = '', toLocation = '', isTrans = '',from = '',to = ''}) => {
      if (!fromLocation || !toLocation || (!isTrans && isTrans !== 0)) {
        return new Promise((resolve, reject) => {
          reject(new Error('fromLocation、toLocation、isTrans不能为空'));
        });
      }
      return http({
        url: BaseurlUat + 'getRoadplanResult?LOP-DN=jdlbsapi.jd.com',
        method: 'POST',
        data: [appKey, { fromLocation, toLocation, isTrans }],
      }) 
      .then(res => {
        // 有from、to且为geojson
        if (!(!isValidMapType(from) || !isValidMapType(to) || from === to) && isTrans == 1) {
          res.result.fenceGeometry = transLatLngs(res.result.fenceGeometry,{from,to})
        } 
        return res
      })
      .catch(err => {
        return err
      })
    },
  };
}
