import L from 'leaflet';
import { TxTileLayer, LayerGroup } from '../layer/index';

// 底图选择器
L.control.underMapSelect = function({
  map,
  position = 'topright',
  collapsed = true,
} = options) {
  var baseLayers = {
    矢量地图: new TxTileLayer(
      'http://rt{s}.map.gtimg.com/tile?z={z}&x={x}&y={y}&styleid=1&version=1084'
    ).addTo(map),
    影像地图: new LayerGroup([
      new TxTileLayer(
        'http://p{s}.map.gtimg.com/sateTiles/{z}/{x16}/{y16}/{x}_{y}.jpg'
      ),
      new TxTileLayer(
        'http://rt{s}.map.gtimg.com/tile?z={z}&x={x}&y={y}&styleid=2&version=1084'
      ),
    ]),
    dem地图: new TxTileLayer(
      'http://p{s}.map.gtimg.com/demTiles/{z}/{x16}/{y16}/{x}_{y}.jpg'
    ),
  };
  return L.control.layers(baseLayers, {}, { position, collapsed }).addTo(map);
};

const control = L.control;

export { control };
