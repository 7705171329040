import L, { Polyline as LPolyline } from "leaflet";
import { TransformFactory } from "../../utils/transformFactory";
import { LayerGroup } from "../layer/LayerGroup";
import { isArray } from "../../utils/common";
class Polyline extends TransformFactory.usePolyline(LPolyline) {
  constructor(latlngs = [], options = {}) {
    super(latlngs, options);
  }
  /**
   * 获取中心点
   * @param {*} from
   * @param {*} to
   * @returns
   */
  // getCenterTrans({ from, to }={}) {
  //   return super._trans(this.getCenter(), { from, to, isReCoordinate: true });
  // }
}

/*
 * @param lineArr [{paht:[], styleId:''}]
 * @param styles {id:{color:'', styleId:''}}
 * @param styleOption  {color:'',weight:2}
 * @param from:''
 * @param to:''
 * @returns LayerGroup
 */
class MultiPolyline {
  constructor({ map, lineArr = [], styles = [], styleOption = {}, from = "", to = "" } = options) {
    if ((isArray(lineArr) && lineArr.length === 0) || !map) {
      return;
    }
    let multiPolyLine = lineArr.map((pathItem) => {
      let style = styles[pathItem.styleId] || {};
      return new Polyline(pathItem.path, {
        ...styleOption,
        ...style,
        from,
        to,
      });
    });
    const polylineLayer = new LayerGroup(multiPolyLine);
    polylineLayer.addTo(map);
    return polylineLayer;
  }
}

export { Polyline, MultiPolyline };
