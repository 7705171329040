export * from "./sdk/map/index";
export * from "./sdk/layer/index";
export * from "./sdk/geometries/index";
export * from "./sdk/basic/index";
export * from "./sdk/tools/index";
export * from "./sdk/service/index";
export * from "./sdk/control/index";
export * from "./utils/index";

import { requestJS } from "./utils/dom.js";

// 奇点埋点
// (function () {
//   if (document.getElementsByName("qidian-sdk").length) return;
//   var _ = window;
//   _.__qd__ || (_.__qd__ = { config: { sid: "56F49", debug: false } });
//   requestJS("qidian-sdk", "https://qdsdk.jd.com/2.0.0/qd.js");
// })();

//子午线埋点
(function () {
  let blackList = ["localhost", "localhost:8080"]; // "jsapi-uat.jdl.com"
  let host = document.location.host;
  if (blackList.indexOf(host) >= 0) {
    return;
  }
  // setTimeout(() => {
  if (document.getElementById("ziwuxian-sdk")) return;
  window.jdpts = {};
  jdpts._st = new Date().getTime();
  var jaq = jaq || [];
  jaq.push(["account", "JA2023_1334348"]);
  jaq.push(["anchorpvflag", "true"]);
  requestJS("ziwuxian-sdk", "https://wl.jd.com/joya.js");
  // }, 500);
})();
