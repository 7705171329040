import L, { Marker as LMarker }from "leaflet";
import { Icon } from "../basic/Icon";
import { LayerGroup } from "../layer/LayerGroup";
import { TransformFactory } from "../../utils/transformFactory";
import { isPlainObject, isArray } from "../../utils/common"


class Marker extends TransformFactory.usePoint(LMarker) {
  constructor(latlng = [], options = {}) {
    super(
      latlng, { 
      icon: new Icon(options),
      ...options
    })
  }

  /**
   * setLatLngTrans
   * 根据坐标转换参数set
   * @param {*} latLng 
   * @param {*} from 
   * @param {*} to 
   */


  /**
   * getLatLngTrans
   * 根据坐标转换参数get
   * @returns latlng
   */
}

/*
    @描述：options
    @默认值：{}
    @格式：
    {
      map: map,
      from: '',
      to: '',
      marks: [
        { id: string,
          styleId: string,
          position: [],
        }
      ],
      styles: {
        object: {
        }
      },
    }
  */
class MultiMarker {
  constructor(options = {}) {
    if (
      !isPlainObject(options) ||
      !options.map ||
      !isArray(options.marks)
    ) {
      return;
    }

    const { id = '', marks = [], map, styles = {}, from = '', to = '' } = options;


    // 优先从markers内参数寻找from，to；最次从options内寻找
    const multiMarker = marks.map((item) => {
      let style = {}, { styleId = null, position = [] } = item;
      style = {
        ...(styles[styleId] ?? {}),
        // 从options寻找坐标转换参数
        ...{from, to},
        // marks的配置中是否含坐标转换参数
        // ...(
        //   item.from && item.to ?
        //   { from: item.from, to: item.to } :
        //   { }
        // ),
        id
      }
      return new Marker(position, style);
    })

    const markerLayer = new LayerGroup(multiMarker);
    markerLayer.addTo(map);
    return markerLayer;
  }
}

export { Marker, MultiMarker };
