// import '@geoman-io/leaflet-geoman-free';
// import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import * as turf from '@turf/turf';
import KeysetTooltip from './KeysetTooltip'
import { maidian } from "../../../utils/dom.js";

class GeometryEditor {
  constructor(map) {
    maidian("sdk_tool", "geometryEditor");
    this._map = map;
    this._drawOptions = null
    this._editOptions = null
    this._moveOptions = null
    this._KeysetTooltip = new KeysetTooltip(map)
    this._layerList = [];
    this._map.on('pm:create', ({ layer }) => {
      this._layerList.push(layer);
    });
  }
  /**
   * @desc 开始绘制
   * @date 2023/3/1
   * @param type 绘制类型
   * @param {*} options 配置项
   * @param callback 配合键盘事件的回调
   * @returns
   */
  addStart(type = '', options = {}, callback ) {
    this._resetOtherOperate()
    this._drawOptions = {
      /* 默认开启键盘交互及提示 */
      tooltips: true,
      cursorMarker: false,
      finishOn: 'dblclick',
      continueDrawing: false,
      ...options
    }
    /* 因为自己定义的tooltips 与源码中的tooltips 会冲突，因此只会保留一个 */
    this._drawOptions.keysetTooltip = this._drawOptions.tooltips
    this._drawOptions.tooltips = false
    // TODO(2023/2/28): 这里使用我们内置的配置，就不再支持原生提供的tooltips --by raopf
    if (this._drawOptions.keysetTooltip) {
      this._KeysetTooltip.initKeysetTooltip((value, config, layer) => {
        if (value === 'cancel') {
          this.addEnd()
        }
        if (callback) {
          callback(value, config, layer)
        }
      }, this._drawOptions)
    }
    this._map.pm.enableDraw(type, this._drawOptions);
  }

  addEnd(isClear = false) {
    if (this._drawOptions?.keysetTooltip) this.clearToolTip()
    isClear && this.clear();
    this._map.pm.disableDraw();
    // this._map.jded.disableDraw();
    this._drawOptions = null
  }

  /**
   * 开始编辑
   * @param {*} layer
   * @param {*} options 配置项
   * @param callback 配合键盘事件的回调
   */
  editStart(layer, options = {}, callback) {
    this._resetOtherOperate()
    this._editOptions = {
      /* 默认开启键盘交互及提示 */
      tooltips: true,
      draggable: true,
      limitMarkersToCount: 100,
      allowSelfIntersection: false,
      allowSelfIntersectionEdit: false,
      ...options
    }
    this._editOptions.keysetTooltip = this._editOptions.tooltips
    this._editOptions.tooltips = false
    if (layer) {
      // 备份图层
      this._workingLayer = layer
      if (this._editOptions.keysetTooltip){
        this._KeysetTooltip.initEditKeysetTooltip((value, config, layer) => {
          if (value === 'cancel') {
            this.editEnd(this._workingLayer)
          } else if (value === 'save') {
            this.editEnd(this._workingLayer)
          } else if (value === 'delete') {
            this.remove(this._workingLayer)
          }
          if (callback) {
            callback(value, config, layer)
          }
        }, this._workingLayer)
      }
      layer.pm.enable(this._editOptions);
    }
  }

  editEnd(layer) {
    if (layer) {
      if (this._editOptions?.keysetTooltip) this.clearToolTip()
      layer.pm.disable();
    }
    this._editOptions = null
  }
  /**
   * @desc 移动元素
   * @date 2023/2/28
   * @param {*} layer
   * @param {*} options 配置项
   * @param callback 配合键盘事件的回调
   * @returns
   */
  moveStart(layer, options, callback) {
    this._resetOtherOperate()
    this._moveOptions = {
      /* 默认开启键盘交互及提示 */
      tooltips: true,
      draggable: true,
      limitMarkersToCount: 100,
      allowSelfIntersection: false,
      allowSelfIntersectionEdit: false,
      ...options
    }
    this._moveOptions.keysetTooltip = this._moveOptions.tooltips
    this._moveOptions.tooltips = false
    if (layer) {
      // 备份图层
      this._workingLayer = layer
      if (this._moveOptions.keysetTooltip) {
        this._KeysetTooltip.initMoveKeysetTooltip((value, config, layer) => {
          if (value === 'cancel') {
            this.moveEnd(this._workingLayer)
          } else if (value === 'save') {
            this.moveEnd(this._workingLayer)
          } else if (value === 'delete') {
            this.remove(this._workingLayer)
          }
          if (callback) {
            callback(value, config, layer)
          }
        }, this._workingLayer)
      }
      if (!layer.pm.enableLayerDrag) {
        layer.eachLayer(item => {
          item.pm.enableLayerDrag();
        })
      } else {
        layer.pm.enableLayerDrag();
      }
    }
  }
  moveEnd(layer) {
    if (layer) {
      if (this._moveOptions?.keysetTooltip) this.clearToolTip()
      if (!layer.pm.disableLayerDrag) {
        layer.eachLayer(item => {
          item.pm.disableLayerDrag();
        })
      } else {
        layer.pm.disableLayerDrag();
      }
    }
    this._moveOptions = null
  }


  remove(layer) {
    if (layer) {
      layer.remove();
      this._layerList = this._layerList.filter(
        layerItem => layerItem !== layer
      );
    }
  }

  union(polygon1, polygon2) {
    return turf.union(polygon1, polygon2);
  }

  cut(line, polygon) {
    if (polygon.geometry.type !== 'Polygon') {
      throw new Error('传入的必须为polygon');
    }
    if (line.geometry.type !== 'LineString') {
      throw new Error('传入的必须为linestring');
    }
    if (line.geometry.type === 'LineString') {
      if (
        turf.booleanPointInPolygon(
          turf.point(line.geometry.coordinates[0]),
          polygon
        ) ||
        turf.booleanPointInPolygon(
          turf.point(
            line.geometry.coordinates[line.geometry.coordinates.length - 1]
          ),
          polygon
        )
      )
        throw new Error('起点和终点必须在多边形之外');
    }
    const bufferDistance = 0.001; // 单位千米
    const lineIntersect = turf.lineIntersect(line, polygon);
    const lineExplode = turf.explode(line);
    lineExplode.features.forEach(feature => {
      lineIntersect.features.push(turf.point(feature.geometry.coordinates));
    });
    const lineBuffer = turf.buffer(line, bufferDistance);
    const differenceResult = turf.difference(polygon, lineBuffer);
    const features = [];
    if (differenceResult.geometry.type === 'Polygon') {
      features.push(turf.polygon(differenceResult.geometry.coordinates));
    } else {
      differenceResult.geometry.coordinates.forEach(coord => {
        features.push(turf.polygon(coord));
      });
    }
    features.forEach(feature => {
      feature.geometry.coordinates[0].forEach((coord, index) => {
        const pointInPolygon = turf.point(coord);
        lineIntersect.features.forEach(pointInLineIntersect => {
          if (
            turf.distance(pointInLineIntersect, pointInPolygon) <=
            bufferDistance * 3
          ) {
            feature.geometry.coordinates[0][index] =
              pointInLineIntersect.geometry.coordinates;
          }
        });
      });
    });
    return turf.featureCollection(features);
  }

  clear() {
    this._layerList.forEach(item => {
      item.remove();
    });

    this._layerList = [];
  }
  /**
   * @desc 设置默认的提示语及键盘事件
   * @date 2023/2/27
   * @param { tooltip, addEventType }
   * @returns
   */
  setTooltipsConfig({ tooltip = {}, addEventType = {} } = {}) {
    this._KeysetTooltip.setKeysetTooltipConfig({
      tooltip,
      addEventType
    })
  }
  /**
   * @desc 重置提示语
   * @date 2023/3/1
   * @returns
   */
  resetTooltipsConfig() {
    this._KeysetTooltip.setKeysetTooltipConfig()
  }
  /**
   * @desc 清除绘制提示及键盘事件
   * @date 2023/2/27
   * @returns
   */
  clearToolTip() {
    this._KeysetTooltip.clearToolTip()
    this._drawOptions = null
    this._editOptions = null
    this._moveOptions = null
  }
  /**
   * @desc 不推荐同时使用多种模式编辑
   * @date 2023/3/8
   * @returns
   */
  _resetOtherOperate() {
    if (this._drawOptions) {
      this.addEnd()
    }
    if (this._editOptions) {
      this.editEnd(this._workingLayer)
    }
    if (this._moveOptions) {
      this.moveEnd(this._workingLayer)
    }
  }
}
export { GeometryEditor };
