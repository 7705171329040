import { http } from "../../utils/http";
import { BASEURL } from "../../config";
import { LatLng } from "../basic/LatLng";
import { Marker } from "../geometries/Marker";

class FocusLocation {
  constructor({
    appKey = null,
    map = null,
    address = "",
    level = null,
    showLocation = true,
  } = {}) {
    if (!map || !address) {
      return new Promise((resolve, reject) => {
        reject(new Error("map、address参数不能为空"));
      });
    }
    this._geoLevel = { 1: 9, 2: 11, 3: 13, 4: 15 };
    this._options = {
      appKey,
      map,
      address,
      level,
      showLocation,
    };
    return http({
      url: BASEURL + "geocode/geo",
      method: "POST",
      data: [this._options.appKey, this._options.address],
    }).then((res) => {
      this.latLng = [res.result.latitude, res.result.longitude];
      var level = this._geoLevel[this._options.level];
      var zoom = level ? level : this._options.map.getZoom();
      this._options.map.setView(new LatLng(this.latLng), zoom);
      if (this._options.showLocation) {
        this.marker = new Marker(this.latLng).addTo(map);
      }
      return this;
    });
  }

  getLatLng() {
    return this.latLng;
  }
  getMaker() {
    return this.marker;
  }
}
export { FocusLocation };
