import { TileLayer } from "../layer/TileLayer";
import { http } from "../../utils/http";
import { YUTUAPI, YTWMS } from "../../config";
import { maidian } from "../../utils/dom.js";

class AoiServer {
  constructor({ map = null, appKey = "", appCode = "", secretKey = "" } = {}) {
    maidian("sdk_service", "aoiServer");
    this._options = {
      map,
      appCode,
      secretKey,
      appKey,
    };
    return http({
      url: YUTUAPI + "/meta/initJsApiConfigData?LOP-DN=meta.map.jd.com",
      method: "POST",
      data: {
        appCode: this._options.appCode,
        secretKey: this._options.secretKey,
        appkey: this._options.appKey,
      },
    })
      .then((res) => {
        const resAoi = JSON.parse(res.data || "{}").aoi || {};
        this.serverConfig = {
          wms: resAoi.wmsServer,
          methods: resAoi.methods,
        };
        return this;
      })
      .catch((e) => {
        this.serverConfig = {
          gbf: {},
          methods: {},
        };
        return this;
      });
  }
  loadAoi() {
    if (!this._options.map) {
      return;
    }
    if (this.serverConfig.wms && this.serverConfig.wms.url) {
      this.aoiLayer && this._options.map.removeLayer(this.aoiLayer)
      this.aoiLayer = TileLayer.wms(YTWMS + this.serverConfig.wms.url, {
        layers: this.serverConfig.wms.layers,
        format: this.serverConfig.wms.format,
        transparent: this.serverConfig.wms.transparent,
        version: this.serverConfig.wms.version,
      }).addTo(this._options.map);
    } else {
      throw error("没有wms权限");
    }
  }
  getAoiLayer() {
    return this.aoiLayer;
  }
  getAoiFenceBylatlng(
    [latitude, longitude],
    isShow = true,
    blockOptions = { fillOpacity: 0.3 }
  ) {
    if (
      this.serverConfig.methods &&
      this.serverConfig.methods.getFenceAoiByPoint
    ) {
      return http({
        url: YUTUAPI + this.serverConfig.methods.getFenceAoiByPoint.url,
        method: "POST",
        data: {
          latitude: latitude,
          longitude: longitude,
          appKey: this._options.appKey,
        },
      }).then((res) => {
        return res;
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("没有getFenceAoiByPoint权限");
      });
    }
  }
  getAoiFenceByScope(elementGeometry, radius, latitude, longitude) {
    if (
      this.serverConfig.methods &&
      this.serverConfig.methods.queryFenceByScope
    ) {
      return http({
        url: YUTUAPI + this.serverConfig.methods.queryFenceByScope.url,
        method: "POST",
        data: {
          type: 2, // 1: 行政围栏 2：aoi 3:业务围栏数据
          elementGeometry: elementGeometry,
          radius: radius,
          latitude: latitude,
          longitude: longitude,
          appKey: this._options.appKey,
        },
      }).then((res) => {
        return res;
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("没有queryFenceByScope权限");
      });
    }
  }
}

export { AoiServer };
