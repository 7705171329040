import { http } from "../../utils/http";
import { BASEURL } from "../../config";
import { transLatLngs, isValidFromAndTo } from "../../utils/transform";
import { maidian } from "../../utils/dom.js";

export function Geocode(appKey) {
  maidian("sdk_service", "geocode");
  return {
    // 地理编码:根据输入的全地址解析定位坐标
    geo: async ({ address = "", from = "", to = "" } = {}) => {
      if (!address) throw new Error("地址不能为空");
      const _result = await http({
        url: BASEURL + "geocode/geo",
        method: "POST",
        data: [appKey, address],
      });
      const { result } = _result;

      // 设置结果里transResult字段记录转换结果
      if (isValidFromAndTo({ from, to }) && result && result.latitude && result.longitude) {
        let [latitude, longitude] = transLatLngs([result.latitude, result.longitude], { from, to });
        _result.transResult = {
          from,
          to,
          latitude,
          longitude,
        };
      }
      return _result;
    },
    // 逆地理编码:根据输入的经纬度坐标解析定位地址文本
    regeo: async ({ lat = "", lng = "", from = "", to = "" } = {}) => {
      if (!lat || !lng) throw new Error("lat、lng参数不能为空");

      if (isValidFromAndTo({ from, to })) [lat, lng] = transLatLngs([lat, lng], { from, to });
      return await http({
        url: BASEURL + "geocode/regeo",
        method: "POST",
        data: [appKey, lat, lng],
      });
    },
  };
}
