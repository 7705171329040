import L from "leaflet";

const typeToFun = {
    image: 'imageOverlay',
    video: 'videoOverlay',
    svg: 'svgOverlay'
}
export class GroundOverlay {
    constructor(url, bounds, options){
        const type = options.type || 'image'
        const fun = typeToFun[type]
        return L[fun](url, bounds, {
            ...options
        })
    }
}
