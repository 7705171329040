import L , {Popup as LPopup}from "leaflet";
import { TransformFactory } from '../../utils/transformFactory';

class Popup extends TransformFactory.usePoint(LPopup) {

  constructor(latlng, options) {
    super(latlng, options)
  }

}
export { Popup };
