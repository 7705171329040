import { bd09togcj02, gcj02tobd09, wgs84togcj02, gcj02towgs84 } from "./coordtransform";
import { MapTypes } from "./enum";
import { LineUtil } from "leaflet";
import { coordEach } from "@turf/turf";
import { cloneDeep } from "lodash";
import { isArray, isObject, isPointArray, isGeoJson, compose } from "./common";
import { maidian } from "./dom.js";

export function isValidMapType(type) {
  return type && Object.values(MapTypes).includes(type);
}

export function isValidFromAndTo({ from = "", to = "" } = {}) {
  return !(!isValidMapType(from) || !isValidMapType(to) || from === to);
}

const transformFnMap = {
  [`${MapTypes.QQMAP}_${MapTypes.BMAP}`]: gcj02tobd09,
  [`${MapTypes.QQMAP}_${MapTypes.TDMAP}`]: gcj02towgs84,

  [`${MapTypes.AMAP}_${MapTypes.BMAP}`]: gcj02tobd09,
  [`${MapTypes.AMAP}_${MapTypes.TDMAP}`]: gcj02towgs84,

  [`${MapTypes.BMAP}_${MapTypes.QQMAP}`]: bd09togcj02,
  [`${MapTypes.BMAP}_${MapTypes.AMAP}`]: bd09togcj02,
  [`${MapTypes.BMAP}_${MapTypes.TDMAP}`]: [gcj02towgs84, bd09togcj02],

  [`${MapTypes.TDMAP}_${MapTypes.QQMAP}`]: wgs84togcj02,
  [`${MapTypes.TDMAP}_${MapTypes.AMAP}`]: wgs84togcj02,
  [`${MapTypes.TDMAP}_${MapTypes.BMAP}`]: [gcj02tobd09, wgs84togcj02],
};

function wrapLatLng(transformLatLng, originLatLng) {
  let parentProto = null;
  if (originLatLng) {
    parentProto = Object.getPrototypeOf(originLatLng);
  }

  // TODO: 数字是否需要
  if (isArray(originLatLng)) {
    Object.setPrototypeOf(transformLatLng, parentProto);
    return transformLatLng;
  }

  if (isObject(originLatLng)) {
    const [lat, lng] = transformLatLng;
    const wrapLatLng = Object.create(parentProto);
    wrapLatLng.lat = lat;
    wrapLatLng.lng = lng;
    return wrapLatLng;
  }

  return transformLatLng;
}

export function coordTransform(letlng, { from, to }) {
  try {
    if (!isValidMapType(from) || !isValidMapType(to) || from === to) return letlng;
    const from_to = `${from}_${to}`;
    const transformFn = transformFnMap[from_to];
    if (!transformFn) return letlng;
    // console.log(transformFn, "transformFn");
    const transformLatLng = Array.isArray(transformFn) ? compose(transformFn)(letlng) : transformFn(letlng);
    return wrapLatLng(transformLatLng, letlng);
  } catch (e) {
    // console.log(e);
  }
}

/**
 * 转换方法
 * @param {*} latlngs   支持所有格式
 * @param {*} options {from，to}
 * @returns
 */
// 统一格式转化
export function transLatLngs(latlngs, options = {}) {
  // maidian("sdk", "transLatLngs");
  try {
    // 点数组
    if (isPointArray(latlngs)) {
      return coordTransform(latlngs, options);
    }
    // 线数组
    if (isArray(latlngs)) {
      return transArrayLatLngs(latlngs, options);
    }
    // geoJson
    if (isGeoJson(latlngs)) {
      return transGeoJsonLatLngs(latlngs, options);
    }
    // 对象
    if (isObject(latlngs) && latlngs.lat && latlngs.lng) {
      return coordTransform(latlngs, options);
    }
    return latlngs;
  } catch (error) {
    // console.log(error);
    return latlngs;
  }
}

/**
 * 数组转换方法
 * @param {*} latlngs   支持 二三多维数组
 * @param {*} options {from，to}
 * @returns
 */
// 数组转换
function transArrayLatLngs(latlngs, options = {}) {
  let result = [],
    flat = LineUtil.isFlat(latlngs);
  for (var i = 0, len = latlngs.length; i < len; i++) {
    if (flat) {
      // 一维数组返回
      result[i] = coordTransform(latlngs[i], options);
    } else {
      // 多维数组递归
      result[i] = transArrayLatLngs(latlngs[i], options);
    }
  }
  return result;
}
/**
 * 数组转换方法
 * @param {*} latlngs   支持geojson格式转化
 * @param {*} options {from，to}
 * @returns
 */
// geoJson转化
function transGeoJsonLatLngs(latlngs, options = {}) {
  let tem = cloneDeep(latlngs);
  coordEach(tem, function (currentCoord, coordIndex, featureIndex, multiFeatureIndex, geometryIndex) {
    let item = coordTransform([currentCoord[1], currentCoord[0]], options);
    currentCoord[0] = item[1];
    currentCoord[1] = item[0];
  });
  return tem;
}
