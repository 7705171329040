export const MapTypes = {
  AMAP: "AMAP",
  AMAP_IMAGE: "AMAP_IMAGE",
  // AMAP_VECTOR: "AMAP_VECTOR",
  // AMAP_LABEL: "AMAP_LABEL",
  // BAIDU:"BAIDU",
  BMAP: "BMAP",
  BMAP_IMAGE:"BMAP_IMAGE",
  // BMAP_VECTOR:"BMAP_VECTOR",
  TDMAP:"TDMAP",
  TDMAP_IMAGE:"TDMAP_IMAGE",
  // TDMAP_VECTOR:"TDMAP_VECTOR",
  // TDMAP_LABEL:"TDMAP_LABEL",
  QQMAP: "QQMAP",
  QQMAP_IMAGE: "QQMAP_IMAGE",
  // QQMAP_VECTOR:"QQMAP_VECTOR",
};
