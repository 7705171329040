import L from "leaflet";

const TxTileLayer = L.TileLayer.extend({
  initialize: function (url, options) {
    options = L.extend(
      {},
      L.TileLayer.prototype.options,
      {
        colorize: function (pixel) {
          return pixel;
        },
        crossOrigin: true,
      },
      options,
      {
        subdomains: "0123",
        maxNativeZoom: 18,
        minZoom: 3,
        //maxZoom: 100,
      }
    );
    L.TileLayer.prototype.initialize.call(this, url, options);
    L.setOptions(this, options);
    this.setColorizr(this.options.colorize);
    this.on("tileload", function (e) {
      this._colorize(e.tile);
    });
  },
  getTileUrl(tilePoint) {
    return L.Util.template(
      this._url,
      L.extend(
        {
          z: tilePoint.z,
          x: tilePoint.x,
          y: Math.pow(2, tilePoint.z) - 1 - tilePoint.y,
          x16: Math.floor(tilePoint.x / 16.0),
          y16: Math.floor((Math.pow(2, tilePoint.z) - 1 - tilePoint.y) / 16.0),
        },
        { s: this._getSubdomain(tilePoint) }
      )
    );
  },
  setColorizr: function (colorizrFactory) {
    if (!colorizrFactory || typeof colorizrFactory !== "function") {
      throw (
        'The colorize option should be a function and return an object with at least one of "r", "g", "b", or "a" properties. Got:' +
        typeof colorizrFactory
      );
    } else {
      this.options.colorize = colorizrFactory;
    }

    this.redraw(true);
  },
  _createTile: function () {
    var tile = L.TileLayer.prototype._createTile.call(this);
    tile.crossOrigin = "Anonymous";
    return tile;
  },
  _colorize: function (img) {
    if (img.getAttribute("data-colorized")) return;

    img.crossOrigin = "";
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var imgd = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var pix = imgd.data;

    for (var i = 0, n = pix.length; i < n; i += 4) {
      var pixel = this.options.colorize({
        r: pix[i],
        g: pix[i + 1],
        b: pix[i + 2],
        a: pix[i + 3],
      });

      if (
        !!!pixel ||
        pixel !== Object(pixel) ||
        Object.prototype.toString.call(pixel) === "[object Array]"
      ) {
        if (i === 0) {
          throw 'The colorize option should return an object with at least one of "r", "g", "b", or "a" properties.';
        }
      } else {
        if (pixel.hasOwnProperty("r") && typeof pixel.r === "number") {
          pix[i] = pixel.r;
        }
        if (pixel.hasOwnProperty("g")) {
          pix[i + 1] = pixel.g;
        }
        if (pixel.hasOwnProperty("b")) {
          pix[i + 2] = pixel.b;
        }
        if (pixel.hasOwnProperty("a")) {
          pix[i + 3] = pixel.a;
        }
      }
    }

    ctx.putImageData(imgd, 0, 0);
    img.setAttribute("data-colorized", true);
    img.src = canvas.toDataURL();
  },
});
class TileLayer {
  constructor(url, options) {
    const TileLayer = L.tileLayer(url, options);
    return TileLayer;
  }
  static wms(url, options) {
    return L.tileLayer.wms(url, options);
  }
}

export { TileLayer, TxTileLayer };
