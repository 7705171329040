import L from "leaflet";
import {ADD_EVENT_TYPE, MOUSE_TOOLTIP} from "./config";
import addMapEvents from './addMapEvents'

class KeysetTooltip {
  constructor(map) {
    this._map = map
    // TODO(2023/3/13): 变量定义 有些繁琐，后期如果有更多的 提示类型，可以考虑 实现一个基类抽离公共方法，子类具体实现功能即可 --by raopf
    // 不同绘制状态下的回调函数
    this._drawCallback = null
    this._editCallback = null
    this._moveCallback = null
    this._drawOptions = null
    this._editOptions = null
    this._moveOptions = null
    this._eventRemoveFn = null // 解绑的函数对象
    this._workingLayer = null // 工作的图层
    this._addEventType = {} // 提示语
    this._mouseTooltip = {} // 提示语绑定的事件
    this._shape = ''
    this.setKeysetTooltipConfig()
    this._map.createPane('drawTipPane');
    /* 第一次添加需要设置经纬度绘导致报错，因此在设置定位度后再添加到地图上 */
    this._tooltip = L.tooltip({
      pane: 'drawTipPane',
      content: '',
      direction: 'right',
      offset: [25, 0],
      opacity: 0.75
    }).setLatLng([0, 0])
  }
  /**
   * @desc 默认开启键盘交互及提示, 可通过 setKeysetTooltipConfig 方法修改内置配置
   * @date 2023/2/27
   * @param callback: 回调函数
   * @returns
   */
  initKeysetTooltip(callback, drawOptions) {
    this._drawCallback = callback
    this._drawOptions = drawOptions
    /* 注册监听绘制开始函数 */
    this._map.on('pm:drawstart', this._onDrawStart);
    this._map.on('pm:create', this._onDrawCreate)
  }
  /**
   * @desc 编辑状态的初始化,由于编辑时需要依赖图层的，因此没办法直接在全局开启监听 不建议直接全局开始编辑
   * @date 2023/2/28
   * @param callback: 回调函数
   * @param layer: 图层
   * @returns
   */
  initEditKeysetTooltip(callback, layer) {
    this._editCallback = callback
    this._workingLayer = layer
    const shape = this._workingLayer.pm._shape || 'Unknow'
    this._setEditTooltipState(shape)
    this._startWatchMove()
  }
  /**
   * @desc 编辑状态的初始化,由于编辑时需要依赖图层的，因此没办法直接在全局开启监听 不建议直接全局开始编辑
   * @date 2023/2/28
   * @param callback: 回调函数
   * @param layer: 图层
   * @returns
   */
  initMoveKeysetTooltip(callback, layer) {
    this._moveCallback = callback
    this._workingLayer = layer
    const shape = this._workingLayer.pm._shape || 'Unknow'
    this._setEditTooltipState(shape, 'move')
    this._startWatchMove()
  }
  _startWatchMove() {
    this._map.on('mousemove', this._onMousemove)
    /* 默认关闭, 在鼠标移动时, 再展示提示，避免一开始显示位置歪的问题 */
    this._tooltip.close()
    /* 添加手势 */
    L.DomUtil.addClass(this._map._container, 'leaflet-cursor-edit')
  }
  /* 绑定事件的函数, 备用方便解绑 */
  _onDrawStart = ({shape, type, workingLayer}) => {
    this._shape = shape
    console.log('开始绘制了', shape)
    this._workingLayer = workingLayer // 工作的图层
    this._setTooltipState(shape, 0)
    if (shape === 'Marker') {
      /* 初始化不显示绘制的点 */
      this._workingLayer.setOpacity(0)
    }
    this._startWatchMove()
    workingLayer.on('pm:vertexadded', (e) => {
      console.log('添加新顶点了', e)
      let length = e.target.getLatLngs().length
      // 给绘制面的第一个点添加hover样式
      if (shape === 'Polygon' && length === 1) {
        L.DomUtil.addClass(e.marker._icon, 'start-point')
      }
      L.DomUtil.addClass(e.marker._icon, 'pm-draw-point')
      this._setTooltipState(shape, length)
    })
    workingLayer.on('pm:centerplaced', (e) => {
      console.log('绘制圆心了', e)
      // 圆绘制没有第二步
      this._setTooltipState(shape, 2)
      this._setCircleRadiusTip(e)
    })
    if (shape === 'Rectangle') {
      this._map.once('click', this._onRectDraw)
    }
  }
  _onDrawCreate = () => {
    if (this._drawOptions.continueDrawing) {
      this._setTooltipState(this._shape, 0)
      return
    }
    this.clearToolTip()
  }
  _onMousemove = ({ latlng }) => {
    // console.log('鼠标在地图上移动了')
    this._tooltip.setLatLng(latlng).addTo(this._map);
    /* 移动时候添加点 */
    if (this._shape === 'Marker') {
      this._workingLayer.setOpacity(1)
    }
  }
  _onRectDraw = () => {
    // 矩形和圆都没有中间绘点能力，直接跳到第二步即可
    const shape = 'Rectangle'
    const length = 2
    this._setTooltipState(shape, length)
  }
  _setCircleRadiusTip(e) {
    if (Reflect.has(e.workingLayer,  '__mRadius')) {
      return
    }
    const self = this
    Reflect.defineProperty(e.workingLayer, '_mRadius', {
      get() {
        return this.__mRadius || 0
      },
      set(val) {
        this.__mRadius = val
        // 增加总距离的提示
        val = (parseInt(val) / 1000).toFixed(2)
        self.setMouseTip(`半径${val}公里<br/>${self._mouseTooltip.ADD_CIRCLE_MORE}`)
      }
    })
  }
  /**
   * @desc 设置绘制状态
   * @date 2023/2/27
   * @param shape: String 形状类型
   * @param step: Number 绘制的步数
   * @returns
   */
  _setTooltipState(shape, step) {
    // 设置操作事件监听, 设置鼠标跟随提示 start first more 三个提示吧，后期可以再扩展更多
    let eventType = null
    switch (step) {
      case -1:
        this.clearToolTip()
        break
      case 0:
        eventType = (`add_${shape}_start`).toUpperCase()
        break
      case 1:
        eventType = (`add_${shape}_first`).toUpperCase()
        break
      case 2:
        eventType = (`add_${shape}_more`).toUpperCase()
        break
      default:
        /* 更多点按照more显示即可 */
        eventType = (`add_${shape}_more`).toUpperCase()
        break
    }
    this.addMapEvents({
      eventType: eventType,
      callback: (value, config, e) => {
        if (value === 'cancel') {
          /* 取消外边自行处理，这里不再处理 */
        } else if (value === 'save') {
          // 触发完成事件 地图双击会自动触发完成事件
          if (config.trigger !== 'dblclick') {
            // 圆 和 矩形需要这个参数
            e.latlng = this._map.pm.Draw[shape]._hintMarker.getLatLng()
            if (shape === 'Marker') {
              this._map.pm.Draw[shape]._createMarker(e)
            } else {
              this._map.pm.Draw[shape]._finishShape(e)
            }
          }
        }
        this._drawCallback(value, config, this._workingLayer)
      }
    })
  }
  /**
   * @desc 二次编辑设置状态
   * @date 2023/2/28
   * @returns
   */
  _setEditTooltipState(shape, type = 'edit') {
    let resetCallback = this._getResetCallback(this._workingLayer)
    const eventType = (`${type}_${shape}`).toUpperCase()
    this.addMapEvents({
      eventType: eventType,
      callback: (value, config, e) => {
        // console.log(value, config, e)
        if (value === 'cancel') {
          resetCallback()
          resetCallback = null
        } else if (value === 'save') {

        } else if (value === 'delete') {}
        if (type === 'edit') {
          this._editCallback(value, config, this._workingLayer)
        } else {
          this._moveCallback(value, config, this._workingLayer)
        }
      }
    })
  }
  _getResetCallback(layer) {
    if (!layer || !layer.pm) {
      return
    }
    let originLatlngs = null
    let originRadius = null
    let resetCallback = null
    const shape = layer.pm._shape || 'Unknow'
    switch (shape) {
      case 'Marker':
        /* 备份原始坐标, 重置备用 */
        originLatlngs = layer.getLatLng()
        resetCallback = () => {
          layer.setLatLng(originLatlngs)
        }
        break
      case 'Circle':
        originLatlngs = layer.getLatLng()
        originRadius = layer.getRadius()
        resetCallback = () => {
          layer.setLatLng(originLatlngs)
          layer.setRadius(originRadius)
        }
        break
      case 'Line':
      case 'Rectangle':
      case 'Polygon':
        originLatlngs = layer.getLatLngs()
        resetCallback = () => {
          layer.setLatLngs(originLatlngs)
        }
        break
      case 'Unknow':
      default:
        originLatlngs = []
        layer.eachLayer(item => {
          originLatlngs.push(this._getResetCallback(item))
        })
        resetCallback = () => {
          originLatlngs.forEach(callback => {
            callback()
          })
        }
        break
    }
    return resetCallback
  }
  /**
   * @desc 获取图层的dom元素
   * @date 2023/2/28
   * @param layer
   * @returns
   */
  _getDOMElem(layer) {
    if (!layer) return
    let el = null;
    if (layer._path) {
      el = layer._path;
    } else if (layer._renderer && layer._renderer._container) {
      el = layer._renderer._container;
    } else if (layer._image) {
      el = layer._image;
    } else if (layer._icon) {
      el = layer._icon;
    }
    return el;
  }
  _restDomClass(layer) {
    const el = this._getDOMElem(layer)
    if (el) {
      L.DomUtil.removeClass(el, 'leaflet-pm-draggable')
    } else {
      if (this._workingLayer?.eachLayer) {
        this._workingLayer?.eachLayer(item => {
          this._restDomClass(item)
        })
      }
    }
  }
  /**
   * @desc 重置提示语及键盘交互
   * @date 2023/2/27
   * @param { MOUSE_TOOLTIP, ADD_EVENT_TYPE }
   * @returns
   */
  setKeysetTooltipConfig({ tooltip = {}, addEventType = {} } = {}) {
    // TODO(2023/2/27): 暂时不支持配置事件列表 如需修改可参照 eventconfig 自行修改传入即可 --by raopf
    /* 重置内部配置 配合 keysetTooltip 开始 */
    this._mouseTooltip = {
      ...MOUSE_TOOLTIP,
      ...tooltip
    }
    this._addEventType = {
      ...ADD_EVENT_TYPE,
      ...addEventType
    }
  }
  /**
   * @desc 设置鼠标跟随的提示语
   * @date 2023/2/27
   * @param tip string
   * @returns
   */
  setMouseTip(tip) {
    this._tooltip.setContent(tip).setLatLng(this._tooltip.getLatLng()).addTo(this._map);
  }

  /**
   * @desc 清除绘制提示及键盘事件
   * @date 2023/2/27
   * @returns
   */
  clearToolTip() {
    this.addMapEvents({
      tip: '',
      eventList: []
    })
    /* 解绑事件、重置数据 */
    this._map.off('mousemove', this._onMousemove)
    this._map.off('click', this._onRectDraw)
    this._map.off('pm:drawstart', this._onDrawStart)
    this._map.off('pm:create', this._onDrawCreate)
    this._tooltip.remove(this._map)
    this._restDomClass(this._workingLayer)
    L.DomUtil.removeClass(this._map._container, 'leaflet-cursor-edit')
    this._eventRemoveFn = null
    /* 配置没必要清理，不然会导致中间状态被清理 */
    // this._workingLayer = null
    // this._drawCallback = null
    // this._editCallback = null
    // this._moveCallback = null
    // this._drawOptions = null
    // this._editOptions = null
    // this._moveOptions = null
  }

  /**
   * @desc 添加键盘事件及提示
   * @date 2023/2/27
   * @param { eventType, callback , ?eventList, ?tip  }
   * @returns
   */
  addMapEvents({eventType, callback, eventList = this._addEventType[eventType], tip = this._mouseTooltip[eventType]})  {
    if (typeof tip === 'string') {
      this.setMouseTip(tip)
    }
    // 解绑之前的事件
    if (this._eventRemoveFn && eventList instanceof Array) {
      this._eventRemoveFn()
      this._eventRemoveFn = null
    }
    this._eventRemoveFn = addMapEvents(this._map, eventList, callback)
  }
}
export default KeysetTooltip
