import L, { GeoJSON as LGeoJSON } from "leaflet";
import { isFunction } from "../../utils/common";
import { TransformFactory } from "../../utils/transformFactory";

var getRandomColor = function () {
  return "#" + ("00000" + ((Math.random() * 0x1000000) << 0).toString(16)).substr(-6);
};

class GeoJSON extends TransformFactory.use(LGeoJSON) {
  static defaultOptions = {
    randomStyle: false,
    hover: {
      open: false,
      cb: function () {},
      activeStyle: {},
    },
    click: {
      open: false,
      openPopup: false,
      popupContent: () => {},
      cb: function () {},
      activeStyle: {},
    },
    center: {
      open: false,
      iconOptions: {},
      contentOptions: {},
    },
  };

  static activeStyle = {
    fillColor: "red",
    fillOpacity: 0.7,
  };

  static defaultStyle = {
    fillOpacity: 0.2,
    fillColor: "#3388ff",
  };

  initialize(geojson, options) {
    this.centerMarkers = [];
    this.activeFeature = null;
    this.activeLayer = null;
    this.centerLayerGroup = null;
    options = { ...GeoJSON.defaultOptions, ...options };
    options = this.initOptions(options);
    LGeoJSON.prototype.initialize.call(this, geojson, options);
  }

  initOptions(options = {}) {
    this.initStyle(options);
    this.initEachFeature(options);
    return options;
  }

  initStyle(options) {
    const { randomStyle, style: styleFn } = options;
    options.style = function (geoJsonFeature) {
      let style = {};
      if (randomStyle) {
        style = {
          fillColor: getRandomColor(),
          fillOpacity: 0.5,
        };
      }

      let userStyle = isFunction(styleFn) ? styleFn(geoJsonFeature) : {};

      style = {
        ...GeoJSON.defaultStyle,
        ...style,
        ...userStyle,
      };
      // (geoJsonFeature.properties = geoJsonFeature.properties || {}).style = style;
      return style;
    };
    return options;
  }

  initHover(feature, layer, { cb: hoverCb, activeStyle = {} }) {
    layer.on("mouseover", () => {
      this.setActiveColor(layer, activeStyle);
      isFunction(hoverCb) && hoverCb(feature, layer, "over");
    });
    layer.on("mouseout", () => {
      this.resetStyle(layer);
      isFunction(hoverCb) && hoverCb(feature, layer, "out");
    });
  }

  initClick(feature, layer, { cb: clickCb, openPopup, popupContent, activeStyle = {} }) {
    if (openPopup) {
      popupContent = isFunction(popupContent) ? popupContent(feature, layer) : popupContent;
      layer.bindPopup(popupContent);
    }
    layer.on("click", () => {
      this.activeLayer && this.resetStyle(this.activeLayer);
      this.setActiveColor(layer, activeStyle);
      this.activeLayer = layer;
      if (openPopup) layer.openPopup();
      isFunction(clickCb) && clickCb(feature, layer);
    });
  }

  initCenter(feature, layer) {
    const center = layer.getBounds().getCenter();
    this.initCenterMarker(center, feature);
  }
  initCenterMarker(latlng, feature) {
    let { contentOptions, iconOptions } = this.options.center;
    if (contentOptions) {
      const htmlContent = this.getCenterHtml(feature, contentOptions);
      let options = { ...contentOptions, html: htmlContent };
      options = { icon: L.divIcon(options) };
      this.centerMarkers.push(L.marker(latlng, { ...options }));
    }
    if (iconOptions) {
      iconOptions = iconOptions.iconUrl ? { icon: L.icon(iconOptions) } : {};
      this.centerMarkers.push(L.marker(latlng, { ...iconOptions }));
    }
  }
  getCenterHtml(feature, contentOptions) {
    const { properties, html = "" } = contentOptions;
    if (properties && feature.properties) {
      return feature.properties[properties] || html || "";
    }
    return html || "";
  }
  initEachFeature(options) {
    const { hover, click, center, onEachFeature } = options;
    options.onEachFeature = (feature, layer) => {
      if (hover.open) this.initHover(feature, layer, hover);

      if (click.open) this.initClick(feature, layer, click);

      if (center.open) this.initCenter(feature, layer);

      isFunction(onEachFeature) && onEachFeature(feature, layer);
    };
  }

  resetLayerStyle(feature) {
    if (!feature) return;
    const { layer, style } = feature.properties;
    if (!layer) throw Error("请绑定图层！");
    const finStyle = {
      ...GeoJSON.defaultStyle,
      ...style,
    };
    layer.setStyle(finStyle);
  }

  setActiveColor(layer, style = GeoJSON.activeStyle) {
    if (!layer) throw Error("请绑定图层！");
    style = {
      ...GeoJSON.activeStyle,
      ...style,
    };
    layer.setStyle(style);
  }

  getCenterLayer() {
    const { center } = this.options;
    if (!center.open) return null;
    return this.centerLayerGroup && this.centerLayerGroup.getLayers();
  }

  removeCenterLayer() {
    const { center } = this.options;
    if (!center.open) return null;
    this.centerLayerGroup && this.centerLayerGroup.remove();
    this.centerLayerGroup = null;
    return this;
  }

  remove() {
    if (this.centerMarkers) this.removeCenterLayer();
    return super.remove();
  }

  addTo(map) {
    if (this.centerMarkers && this.centerMarkers.length > 0) {
      this.centerLayerGroup = L.layerGroup(this.centerMarkers).addTo(map);
    }
    return super.addTo(map);
  }
}

export { GeoJSON };
