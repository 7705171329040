import L from 'leaflet';
import markerIcon from '../images/marker-icon.png';
import markerShadow from '../images/marker-shadow.png';
import DefaultIcon from '../images/default-icon.png';
import '../css/iconExtend.css';
class Icon {
  constructor(options = {}) {
    const icon = L.icon({
      iconUrl: markerIcon,
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
      popupAnchor: [12.5, 21.5],
      shadowUrl: markerShadow,
      shadowSize: [41, 41],
      shadowAnchor: [15, 41.5],
      ...options,
    });
    return icon;
  }
}

class DivIcon {
  constructor(options = {}) {
    const divIcon = L.divIcon(options);
    return divIcon;
  }
}

// 定义icon类以创建一组图标使用相同属性的图标

class NumberIcon {
  constructor(options = {}) {
    const numberIcon = L.Icon.extend({
      options: {
        iconSize: new L.Point(60, 60),
        iconAnchor: new L.Point(30, 30),
      },
      createIcon: function() {
        const {
          iconUrl,
          iconClassName,
          iconSize,
          iconRotate,
          iconRotateOrigin,
          number,
          numberClassName,
          numberRotate,
          numberRotateOrigin,
          rotate,
          rotateOrigin,
        } = options;
        const height = Array.isArray(iconSize) ? iconSize[1] : 60;
        const width = Array.isArray(iconSize) ? iconSize[0] : 60;
        const img = document.createElement('img');
        img.src = iconUrl || DefaultIcon;
        img.style.width = `${width}px`;
        img.style.height = `${height}px`;
        iconRotate && (img.style.transform = `rotate(${iconRotate}deg)`);
        iconRotateOrigin && (img.style.transformOrigin = iconRotateOrigin);
        iconClassName && (img.className += ` ${iconClassName}`);
        var numdiv = document.createElement('div');
        numdiv.setAttribute('class', 'icon-number');
        numdiv.innerHTML = number || '';
        numdiv.style.height = `${height}px`;
        numdiv.style.lineHeight = `${height}px`;
        numberClassName && (numdiv.className += ` ${numberClassName}`);
        numberRotate && (numdiv.style.transform = `rotate(${numberRotate}deg)`);
        numberRotateOrigin &&
          (numdiv.style.transformOrigin = numberRotateOrigin);
        var outer = document.createElement('div');
        var box = document.createElement('div');
        rotate && (box.style.transform = `rotate(${rotate}deg)`);
        rotateOrigin && (box.style.transformOrigin = rotateOrigin);
        outer.appendChild(box);
        box.appendChild(img);
        box.appendChild(numdiv);
        this._setIconStyles(outer, 'icon');
        return outer;
      },
    });
    return new numberIcon(options);
  }
}

export { Icon, DivIcon, NumberIcon };
