import L from "leaflet";
class LayerGroup {
  constructor(layers, options = {}) {
    const layerGroup = L.layerGroup(layers, {
      ...options,
    });
    return layerGroup;
  }
}
export { LayerGroup };
