import { http } from '../../utils/http';
import { BASEURL, YTURL } from '../../config';
import {isValidMapType,transLatLngs} from '../../utils/transform'
import { maidian } from "../../utils/dom.js";

export function Location(appKey = '') {
  maidian("sdk_service", "location");
  // 获取ip
  this.getClientIp = function() {
    return http({
      url: YTURL + 'common/getClientIp?LOP-DN=userauthority.jdl.com',
      method: 'POST',
      data: {},
    });
  };

  // ip定位方法
  this.getLocationByIp = function(ip) {
    if (!ip) {
      return new Promise((resolve, reject) => {
        reject(new Error('ip不能为空'));
      });
    }
    return http({
      url: BASEURL + 'getLocationByIp',
      method: 'POST',
      data: [appKey, { ip: ip }],
    });
  };
  // ? 基于ip获取的定位坐标系？
  this.getLocation = function({from = '',to = ''} = {}) {
    return new Promise((resolve, reject) => {
      try {
        this.getClientIp()
          .then(res => {
            this.getLocationByIp(res.data)
              .then(res => {
                // 坐标转换
                if (!(!isValidMapType(from) || !isValidMapType(to) || from === to)) {
                  const transPosition = transLatLngs([res.result.lat,res.result.lng],{from,to})
                  res.result.latitudeTrans = transPosition[0]
                  res.result.longitudeTrans = transPosition[1]
                } 
                resolve(res);
              })
              .catch(e => {
                reject(e);
              });
          })
          .catch(e => {
            reject(e);
          });
      } catch (e) {
        reject(e);
      }
    });
  };
}
