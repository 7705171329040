import { Geocode } from './Geocode';
import { Route } from './Route';
import { AddressTrans } from './AddressTrans';
import { Location } from './Location';
import { AoiServer } from './AoiServer';
import { GbfServer } from './GbfServer';

export const service = {
  Route,
  Geocode,
  AddressTrans,
  Location,
  AoiServer,
  GbfServer,
};
