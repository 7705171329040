import L ,{Tooltip as LTooltip}from 'leaflet';
import { TransformFactory } from '../../utils/transformFactory';
class Tooltip extends TransformFactory.usePoint(LTooltip) {

  constructor(latlng, options ) {
    super(latlng, options)
  }

  /**setLatLngTrans */

  /**getLatLngTrans */

}
export { Tooltip };
