import L from "leaflet";
import car from "../images/car.png";
import markerShadow from "../images/marker-shadow.png";
import { Marker } from "../geometries/Marker";
import { Polyline } from "../geometries/Polyline";
import { LatLng } from "../basic/LatLng";
import { TransformFactory } from "../../utils/transformFactory";
import {isValidMapType} from '../../utils/transform'
import { cloneDeep } from 'lodash'
import { isArray } from "../../utils/common";
import { maidian } from "../../utils/dom.js";
class Track {
  constructor({
    map = null,
    path = null,
    speed = 1,
    from = '',
    to = '',
    autoRotation = true,
    moverStyle,
    lineStyle,
    movedLineStyle,
    autoStart = true,
    circleRun = false,
    isDrawPath = false,
    isMovedPath = true,
    isShowText = false,
    textStyle,
  }) {
    maidian("sdk_tool", "track");
    if (!map || speed <= 0) {
      return;
    }
    this._options = {
      map,
      path,
      speed,
      autoRotation,
      lineStyle,
      movedLineStyle,
      autoStart,
      circleRun,
      isDrawPath,
      isMovedPath,
      isShowText,
      from,
      to,
    };
    this._options.moverStyle = {
      iconUrl: car,
      shadowUrl: markerShadow,
      iconSize: [50, 40],
      iconAnchor: [25, 20],
      ...moverStyle,
    };
    this._options.textStyle = {
      html: "",
      className: "",
      ...textStyle,
    };
    // 坐标转换器
    this._transFactory = new TransformFactory(this._options);
    this._transLatlngs = null;
    // 计算
    this._pause = false;
    this._i = 0;
    this._trackTextDiv = null;
    this._setZooming();
    // 绘制
    if (path && path.length > 0) {
      this._latlngs = this._chunk(path);
      this._initFeature();
      if (this._options.autoStart) {
        this.start();
      }
    }
  }

  // mark、polyline绘制
  _initFeature() {
    this._icon = this._setIcon(this._options.moverStyle);
    if (this._mover) {
      this._mover.remove();
    }
    this._mover = new Marker(this._latlngs[0], {
      icon: this._icon,
    });
    this._mover.addTo(this._options.map);

    if (this._options.isDrawPath) {
      this._polyline = new Polyline(
        this._latlngs,
        this._options.lineStyle
      );
      this._polyline.addTo(this._options.map);
    }
    if (this._options.isMovedPath) {
      this._movedPath = new L.Polyline([], this._options.movedLineStyle);
      this._movedPath.addTo(this._options.map);
    }
  }

  // 重新开始
  reStart() {
    let featureStatus = true;
    for (const key in this._options.map._layers) {
      if (this._options.map._layers[key] === this._mover) {
        featureStatus = false;
      }
    }
    if (featureStatus) {
      this._initFeature();
      this._setZooming();
    } else {
      // this._polyline.setLatLngs(this._options.path);
      this._mover.setLatLng(this._options.path[0]);
      this._movedPath && this._movedPath.setLatLngs([]);
    }
    clearTimeout(this._tid);
    this._i = 0;
    this._pause = false;
    this._animate();
  }

  // 解决缩放问题
  _setZooming() {
    this._zoomstartFun = () => {
      this._isZooming = true;
    };
    this._zoomendFun = () => {
      this._isZooming = false;
    };
    this._isZooming = false;
    this._zoomstart = this._options.map.addEventListener(
      "zoomstart",
      this._zoomstartFun
    );
    this._zoomend = this._options.map.addEventListener(
      "zoomend",
      this._zoomendFun
    );
  }

  getMover() {
    return this._mover;
  }

  // 动画
  _animate() {
    if (!this._mover) {
      return;
    }
    var self = this,
      len = this._latlngs.length,
      speed = this._options.speed,
      i_latlng = this._latlngs[this._i];
    // 计算每一节的速度
    if (this._i < len && this._i > 0) {
      speed =
        (this._latlngs[this._i - 1].distanceTo(i_latlng) /
          this._options.speed) *
        10;
    }
    if (!this._isZooming && this._i < len) {
      if (this._options.autoRotation) {
        this._icon.rotate(i_latlng.bearing);
      }
      this._mover.setLatLng(i_latlng);
      this._movedPath && this._movedPath.addLatLng(i_latlng);
      this._i++;
    }
    this._tid = setTimeout(function () {
      if (self._i < len) {
        if (!self._pause) {
          self._animate();
        }
      } else {
        // 循环播放
        if (self._options.circleRun) {
          self._i = 0;
          self._movedPath && self._movedPath.setLatLngs([]);
          self._animate();
        }
      }
    }, speed);
  }

  // 暂停
  pause() {
    this._pause = true;
  }
  // 开始
  start() {
    this._pause = false;
    this._animate();
  }
  // 结束、清空
  end() {
    this._mover && this._mover.remove();
    this._mover = null;
    this._pause = true;
    clearTimeout(this._tid);
    this._options.map.removeEventListener("zoomstart", this._zoomstartFun);
    this._options.map.removeEventListener("zoomend", this._zoomendFun);
    this._polyline && this._polyline.remove();
    this._movedPath && this._movedPath.remove();
  }

  // 设置divIcon替换icon,实现旋转
  _setIcon(options) {
    const _this = this;
    var Icon = L.DivIcon.extend({
      createIcon: function () {
        var outerDiv = document.createElement("div");
        // outerDiv.style.width = options.iconSize[0] + 'px';
        // outerDiv.style.height = options.iconSize[1] + 'px';
        _this.div = document.createElement("div");
        outerDiv.style.position = "absolute";
        _this.div.style.width = options.iconSize[0] + "px";
        _this.div.style.height = options.iconSize[1] + "px";
        _this.div.style.transition = "transform linear 100ms";
        _this.div.style.transformOrigin = "center";
        _this.div.style.transform =
          " translate3d(-" +
          options.iconAnchor[0] +
          "px, -" +
          options.iconAnchor[1] +
          "px, 0) rotate(-" +
          _this._latlngs[0].bearing +
          "deg)";
        const img = document.createElement("img");
        img.src = options.iconUrl;
        img.width = options.iconSize[0];
        img.height = options.iconSize[1];
        _this.div.appendChild(img);
        outerDiv.appendChild(_this.div);
        if (_this._options.isShowText) {
          let { className, html } = _this._options.textStyle;
          _this._trackTextDiv = document.createElement("div");
          _this._trackTextDiv.innerHTML = html;
          _this._trackTextDiv.setAttribute("class", "track-text");
          className && (_this._trackTextDiv.className += ` ${className}`);
          outerDiv.appendChild(_this._trackTextDiv);
        }
        return outerDiv;
      },
      rotate(deg) {
        if (_this.before && Math.abs(_this.before - deg) >= 180) {
          _this.div.style.transition = "none";
        } else {
          _this.div.style.transition = "transform linear 100ms";
        }
        _this.div.style.transform =
          "translate3d(-" +
          options.iconAnchor[0] +
          "px, -" +
          options.iconAnchor[1] +
          "px, 0) rotate(-" +
          deg +
          "deg)";
        _this.before = deg;
      },
      iconSize: options.iconSize,
    });
    return new Icon();
  }

  // 经纬度插值
  _chunk(latlngs) {
    if (!(!isValidMapType(this._options.from) || !isValidMapType(this._options.to) || this._options.from === this._options.to)) {
      latlngs = this._transFactory.trans(latlngs)
      this._transLatlngs = cloneDeep(latlngs)
    }
    var i,
      len = latlngs.length,
      chunkedLatLngs = [];
    const distance = this._options.speed;
    for (i = 1; i < len; i++) {
      var cur = new JDMap.LatLng(latlngs[i - 1]),
        next = new JDMap.LatLng(latlngs[i]),
        dist = cur.distanceTo(next),
        factor = distance / dist,
        dLat = factor * (next.lat - cur.lat),
        dLng = factor * (next.lng - cur.lng),
        rotate = this._getRotation(latlngs[i - 1], latlngs[i]);
      if (dist > distance) {
        chunkedLatLngs.push(cur);
        cur.bearing = rotate;
        while (dist > distance) {
          cur = new LatLng(cur.lat + dLat, cur.lng + dLng);
          dist = cur.distanceTo(next);
          cur.bearing = rotate;
          chunkedLatLngs.push(cur);
        }
      } else {
        chunkedLatLngs.push(cur);
        cur.bearing = rotate;
      }
    }
    chunkedLatLngs.push(new JDMap.LatLng(latlngs[len - 1]));
    return chunkedLatLngs;
  }

  // 角度旋转
  _getRotation(startLatLng, endLatLng) {
    let objStartLatLng,objEndLatLng
    if(isArray(startLatLng)) {
      objStartLatLng = new JDMap.LatLng(startLatLng)
    } else {
      objStartLatLng = startLatLng
    }
    if(isArray(endLatLng)) {
      objEndLatLng = new JDMap.LatLng(endLatLng)
    } else {
      objEndLatLng = endLatLng
    }
    var dx = objEndLatLng.lng - objStartLatLng.lng;
    var dy = objEndLatLng.lat - objStartLatLng.lat;
    var radian = Math.atan2(dy, dx); // 弧度值
    var rotation = parseInt((180 * radian) / Math.PI); // 转换为角度值
    if (rotation > -180 && rotation < 0) {
      rotation = 360 + rotation;
    }
    return rotation;
  }

  // 设置路径
  setPath(path) {
    if (path) {
      this._options.path = path;
      this._latlngs = this._chunk(path);
      this.reStart();
    }
  }

  setTrackText(html = "") {
    const _this = this;
    if (!_this._options.isShowText) {
      return;
    }
    _this._trackTextDiv.innerHTML = html;
  }

  setTrackToPos(latlng) {
    if ((!latlng || !latlng.lat || !latlng.lng) && (latlng.length !==2)) {
      return;
    }
    if (!(!isValidMapType(this._options.from) || !isValidMapType(this._options.to) || this._options.from === this._options.to)) {
      latlng = this._transFactory.trans(latlng)
    }
    if(isArray(latlng)) {
      latlng = new JDMap.LatLng(latlng)
    }
    var _this = this;
    if (_this._pause == false) {
      _this.pause();
      setTimeout(() => {
        _this.start();
      }, 300);
    }
    var pId = this._latlngs.findIndex(
      (item) => item.lat == latlng.lat && item.lng == latlng.lng
    );

    if (pId == -1) return;
    _this._mover && _this._mover.setLatLng(this._latlngs[pId]);
    this._icon.rotate(this._latlngs[pId].bearing);
    if (_this._movedPath && pId > -1) {
      this._i = pId;
      this._movedPath.setLatLngs(_this._latlngs.slice(0, pId + 1));
    }
  }

  // 获取路径
  getPath() {
    return this._options.path;
  }
  // 获取转换后的路径
  getTransPath() {
    return  this._transLatlngs
  }

  // 设置速度
  setSpeed(speed) {
    if (speed && speed > 0) {
      this._options.speed = speed;
      this._latlngs = this._chunk(this._options.path);
      this.reStart();
    }
  }

  // 获取速度
  getSpeed() {
    return this._options.speed;
  }

  // 设置转向
  // setAutoRotation(autoRotation) {
  //   if (autoRotation) {
  //     this._options.autoRotation = autoRotation;
  //   }
  // }

  // 获取转向
  getAutoRotation() {
    return this._options.autoRotation;
  }
  // 设置移动点样式
  // setMoverStyle(moverStyle) {
  //   this._options.moverStyle = moverStyle;
  // }

  // 获取移动点样式
  getMoverStyle() {
    return this._options.moverStyle;
  }
  // 设置移动线样式
  // setLineStyle(lineStyle) {
  //   this._options.lineStyle = lineStyle;
  // }

  // 获取移动线样式
  getLineStyle() {
    return this._options.lineStyle;
  }
  // 设置是否自动启动
  // setAutoStart(autoStart) {
  //   this._options.autoStart = autoStart;
  // }

  // 获取是否自动启动
  getAutoStart() {
    return this._options.autoStart;
  }
  // 设置是否循环播放
  // setCircleRun(circleRun) {
  //   this._options.circleRun = circleRun;
  // }

  // 获取是否循环播放
  getCircleRun() {
    return this._options.circleRun;
  }
}
export { Track };
