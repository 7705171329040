export function http({
  url,
  method = 'get',
  data = '',
  appCode = '',
  secretKey = '',
}) {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          try {
            resolve(JSON.parse(xhr.responseText));
          } catch (e) {}
        } else {
          reject(JSON.parse(xhr.responseText));
        }
      }
    };
    if (method.toUpperCase() === 'GET') {
      const paramsList = [];
      for (const key in data) {
        paramsList.push(key + '=' + data[key]);
      }
      const params = paramsList.join('&');
      url = url + '?' + params;
      xhr.open('get', url, true);
      xhr.send();
    } else if (method.toUpperCase() === 'POST') {
      xhr.open('post', url, true);
      if (typeof data === 'object') {
        try {
          data = JSON.stringify(data);
        } catch (e) {}
      }
      appCode && xhr.setRequestHeader('appCode', appCode);
      secretKey && xhr.setRequestHeader('secretKey', secretKey);
      xhr.send(data);
    }
  });
  return promise;
}

export function getHttpRequest() {
  if (window.XMLHttpRequest) return new XMLHttpRequest();
  else if (window.ActiveXObject) return new ActiveXObject('MsXml2.XmlHttp');
}
