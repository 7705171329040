import { TileLayer } from "../layer/TileLayer";
import { http } from "../../utils/http";
import { YUTUAPI, YTWMS } from "../../config";
import { polygon } from "@turf/turf";
import { GeoJSON } from "../layer/GeoJSON";
import { maidian } from "../../utils/dom.js";
class GbfServer {
  constructor({ map = null, appKey = "", appCode = "", secretKey = "" } = {}) {
    maidian("sdk_service", "gbfServer");
    this._options = {
      map,
      appCode,
      secretKey,
      appKey,
    };
    this._levelConfig = {
      1: [0, 5],
      2: [5, 8],
      3: [8, 10],
      4: [10, 18],
    };
    return http({
      url: YUTUAPI + "/meta/initJsApiConfigData?LOP-DN=meta.map.jd.com",
      method: "POST",
      data: {
        appCode: this._options.appCode,
        secretKey: this._options.secretKey,
        appkey: this._options.appKey,
      },
    })
      .then((res) => {
        const resGbf = JSON.parse(res.data || "{}").gbf || {};
        this.serverConfig = {
          gbf: resGbf.wmsServer,
          methods: resGbf.methods,
        };
        return this;
      })
      .catch((e) => {
        this.serverConfig = {
          gbf: {},
          methods: {},
        };
        return this;
      });
  }
  loadGbf() {
    if (!this._options.map) {
      return;
    }
    this._level = this._getGbfLevel(this._options.map.getZoom());
    this._searchGbf();
    this._options.map.on("zoomend", ({ target: { _zoom = 0 } }) => {
      this._level = this._getGbfLevel(_zoom);
      this._searchGbf();
    });
  }
  getGbfLayer() {
    return this.gbfLayer;
  }
  _searchGbf() {
    if (this.gbfLayer) {
      this.gbfLayer && this._options.map.removeLayer(this.gbfLayer);
      this.gbfLayer = null;
    }
    if (this.serverConfig.gbf && this.serverConfig.gbf.url) {
      var wmsLayer = TileLayer.wms(YTWMS + this.serverConfig.gbf.url, {
        layers: this.serverConfig.gbf.layers,
        format: this.serverConfig.gbf.format,
        CQL_FILTER: "level=" + this._level,
        transparent: this.serverConfig.gbf.transparent,
        version: this.serverConfig.gbf.version,
      });
      this.gbfLayer = wmsLayer.addTo(this._options.map);
    } else {
      throw error("没有wms权限");
    }
  }
  _getGbfLevel(zoom) {
    var level = Object.keys(this._levelConfig).find(
      (key) =>
        zoom > this._levelConfig[key][0] && zoom <= this._levelConfig[key][1]
    );
    return level || 1;
  }
  getGbfBylatlng([latitude, longitude]) {
    if (
      this.serverConfig.methods &&
      this.serverConfig.methods.getFenceIdByPoint
    ) {
      return http({
        url: YUTUAPI + this.serverConfig.methods.getFenceIdByPoint.url,
        method: "POST",
        data: {
          latitude: latitude,
          longitude: longitude,
          appKey: this._options.appKey,
          secretKey: this._options.secretKey,
        },
      }).then((res) => {
        return res;
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("没有getFenceIdByPoint权限");
      });
    }
  }
  getGbfByScope(level, elementGeometry, radius, latitude, longitude) {
    if (
      this.serverConfig.methods &&
      this.serverConfig.methods.queryFenceByScope
    ) {
      return http({
        url: YUTUAPI + this.serverConfig.methods.queryFenceByScope.url,
        method: "POST",
        data: {
          level: level,
          type: 1, //1: 行政围栏 2：aoi 3:业务围栏数据
          elementGeometry: elementGeometry,
          radius: radius,
          latitude: latitude,
          longitude: longitude,
          appKey: this._options.appKey,
          secretKey: this._options.secretKey,
        },
      }).then((res) => {
        return res;
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("没有queryFenceByScope权限");
      });
    }
  }
  gbfLocationByDistrictCode(districtCode, blockOptions = {}, showBlock = true) {
    const options = {
      fillOpacity: 0.3,
      ...blockOptions,
    };
    if (
      this.serverConfig.methods &&
      this.serverConfig.methods.getFenceIdByDistrictCode
    ) {
      return http({
        url: YUTUAPI + this.serverConfig.methods.getFenceIdByDistrictCode.url,
        method: "POST",
        data: {
          districtCode: districtCode,
          showFence: 1, // showFence:  1：显示 0：不显示, 默认不显示
          appKey: this._options.appKey,
          secretKey: this._options.secretKey,
        },
      }).then((res) => {
        const { fenceWkt } = res.data;
        let geoJson = polygon(JSON.parse(fenceWkt).coordinates[0]);
        let geoJsonLayer = new GeoJSON(geoJson, options);
        this._options.map.fitBounds(geoJsonLayer.getBounds());
        if (showBlock) {
          geoJsonLayer.addTo(this._options.map);
          return geoJsonLayer;
        }
        return;
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("没有getFenceIdByDistrictCode权限");
      });
    }
  }
}
export { GbfServer };
