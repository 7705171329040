import { transLatLngs } from "./transform";
import { LatLngBounds, LatLng } from "leaflet";
import { isArray, isGeoJson } from "./common";

/**
 * copy
 * @param {*} target
 * @param {*} source
 */
function copyProperties(target, source) {
  for (let key of Reflect.ownKeys(source)) {
    if (key !== "constructor" && key !== "prototype" && key !== "name") {
      let desc = Object.getOwnPropertyDescriptor(source, key);
      Object.defineProperty(target, key, desc);
    }
  }
}

/**
 * 转换工厂
 */
class TransformFactory {
  _coordinateProps = {
    from: "",
    to: "",
  };

  // 设置转换参数
  set coordinateProps(options = {}) {
    this._coordinateProps = {
      from: (options.from || "").toUpperCase(),
      to: (options.to || "").toUpperCase(),
    };
    Object.freeze(this._coordinateProps);
  }

  // 获取from-to参数
  get coordinateProps() {
    return this._coordinateProps;
  }

  // 获取to-from参数
  get reCoordinateProps() {
    return {
      from: this._coordinateProps.to,
      to: this._coordinateProps.from,
    };
  }

  constructor(options) {
    this.coordinateProps = options;
  }

  static use(LClass) {
    if (!LClass || !LClass.prototype || !LClass.prototype.initialize) throw new Error("only can extend special class");
    return class extends LClass {
      _transFactory = null;

      constructor(latlng = [], options) {
        let _transFactory = new TransformFactory(options);
        if (latlng && (latlng instanceof LatLng || isArray(latlng) || isGeoJson(latlng))) {
          latlng = _transFactory.trans(latlng, options);
        }
        super(latlng, options);

        this._transFactory = _transFactory;
      }

      _trans(...args) {
        return this._transFactory.trans(...args);
      }

      //TODO 这里暂未包含toGeoJSON()的precision参数
      toGeoJSONTrans({ from, to, precision = 12 } = {}) {
        return this._trans(this.toGeoJSON(precision), {
          from,
          to,
          isReCoordinate: true,
        });
      }

      getBoundsTrans({ from, to } = {}) {
        if (this.getBounds) {
          let bounds = this.getBounds();
          let southWest = this._trans(bounds.getSouthWest(), { from, to, isReCoordinate: true });
          let northEast = this._trans(bounds.getNorthEast(), { from, to, isReCoordinate: true });
          return new LatLngBounds(southWest, northEast);
        } else {
          throw new Error("it has no getBounds method");
        }
      }
    };
  }

  static usePoint(LClass) {
    return class extends TransformFactory.use(LClass) {
      constructor(...args) {
        super(...args);
      }

      setLatLngTrans(latLng, { from, to } = {}) {
        let _latlng = super._trans(latLng, { from, to });
        return this.setLatLng(_latlng);
      }

      getLatLngTrans({ from, to } = {}) {
        return this._trans(this.getLatLng(), { from, to, isReCoordinate: true });
      }
    };
  }

  static usePolyline(LClass) {
    return class extends TransformFactory.use(LClass) {
      constructor(...args) {
        super(...args);
      }

      setLatLngsTrans(latLng, { from, to } = {}) {
        let _latlng = super._trans(latLng, { from, to });
        this.setLatLngs(_latlng);
      }

      getLatLngsTrans({ from, to } = {}) {
        return this._trans(this.getLatLngs(), { from, to, isReCoordinate: true });
      }

      getCenterTrans({ from, to } = {}) {
        return this._trans(this.getCenter(), { from, to, isReCoordinate: true });
      }
    };
  }
  /**
   * 转换方法
   * @param {*} latlng
   * @param {*} param1 如果设置from|to优先使用，根据isReCoordinate判断使用存储的参数
   * @returns
   */
  trans(latlng, { from, to, isReCoordinate = false } = {}) {
    let props =
      from && to
        ? {
            from,
            to,
          }
        : isReCoordinate
        ? this.reCoordinateProps
        : this.coordinateProps;
    return transLatLngs(latlng, props);
  }
}

export { copyProperties, TransformFactory };
