import L, { Circle as LCircle , CircleMarker as LCircleMarker, Util } from 'leaflet';
import { TransformFactory } from '../../utils/transformFactory';
import { isNumber } from '../../utils/common'

class Circle extends TransformFactory.usePoint(LCircle) {

  constructor(latlng, options, legacyOptions = {}) {
    if (isNumber(options)) {
			options = {
        ...legacyOptions,
        radius: options
      };
		}
    super(latlng, options)
  }

  /**setLatLngTrans */

  /**getLatLngTrans */

  /**getBounds */
}

class CircleMarker extends TransformFactory.usePoint(LCircleMarker) {

  constructor(latlng, options) {
    super(latlng, options)
  }

  /**setLatLngTrans */

  /**getLatLngTrans */

}
export { Circle, CircleMarker };
