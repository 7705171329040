import L, { Map, Util } from "leaflet";
import "leaflet/dist/leaflet.css";
import "proj4";
import "proj4leaflet";
import "../plugins/chineseTmsProviders";
import { MapEnums } from "../../utils";
import { includeJS, maidian } from "../../utils/dom.js";
import { getHttpRequest } from "../../utils/http";
import { isObject } from "../../utils/common";
import { coordTransform } from "../../utils/transform";
class JDMap extends Map {
  initialize(el, options) {
    // 初始化地图上报埋点
    // 奇点埋点
    // window.__qd__.click({
    //   cls: "9BA0|104226",
    //   v: {
    //     param1: "new map",
    //   },
    // });
    // 子午线埋点
    maidian("sdk", "newMap");

    options = this.initOptions(options);
    Map.prototype.initialize.call(this, el, options);
    if (this.options.hideMapTile) return this;
    // 修改options指向
    // this.options.__proto__.crs = crs;当为百度map的时候会把挂载多个map的options的crs都变为L.CRS.Baidu
    Util.setOptions(this, this.options);
    this.changeMap(this.options);
  }
  initOptions(options = {}) {
    const { MapTypes } = MapEnums;
    const defaultOptions = {
      center: [39.908173, 116.397496],
      zoom: 13,
      mapFactory: MapTypes.QQMAP,
    };
    const { mapFactory } = options;
    if (mapFactory === MapTypes.BMAP) options = { ...options, crs: L.CRS.Baidu };
    return {
      ...defaultOptions,
      ...options,
    };
  }
  initQQMap(options = {}) {
    const normalm = L.tileLayer.chinaProvider("Tencent.Normal.Map", { ...options });
    return [normalm];
  }
  initAMap(options = {}) {
    const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", { ...options });
    return [normalm];
  }
  initTDMap(options = {}) {
    const normalm = L.tileLayer.chinaProvider("TianDiTu.Normal.Map", {
      key: "93724b915d1898d946ca7dc7b765dda5",
      ...options,
    });
    const normala = L.tileLayer.chinaProvider("TianDiTu.Normal.Annotion", {
      key: "93724b915d1898d946ca7dc7b765dda5",
      ...options,
    });
    return [normalm, normala];
  }
  initBMap(options = {}) {
    const normalm = L.tileLayer.chinaProvider("Baidu.Normal.Map", { ...options });
    return [normalm];
  }
  initTileLayer(options) {
    const { MapTypes } = MapEnums;
    const { mapFactory = MapTypes.QQMAP, styleid } = options;
    let defaulTileLayertOptions = {
      maxZoom: 18,
      minZoom: 3,
      mapFactory,
    };
    if (styleid) defaulTileLayertOptions = { ...defaulTileLayertOptions, styleid };
    let mapGroup = [];
    switch (mapFactory) {
      case MapTypes.QQMAP:
        mapGroup = this.initQQMap(defaulTileLayertOptions);
        break;
      case MapTypes.AMAP:
        mapGroup = this.initAMap(defaulTileLayertOptions);
        break;
      case MapTypes.TDMAP:
        mapGroup = this.initTDMap(defaulTileLayertOptions);
        break;
      case MapTypes.BMAP:
        mapGroup = this.initBMap(defaulTileLayertOptions);
        break;
      default:
        mapGroup = this.initQQMap(defaulTileLayertOptions);
        break;
    }
    return mapGroup;
  }

  changeMap(type) {
    const { MapTypes } = MapEnums;
    type = isObject(type) ? type : { mapFactory: type };
    const { mapFactory } = type;
    const { mapFactory: oldMapFactory = MapTypes.QQMAP } = this.options;
    const oldCenter = this.getCenter();
    this.options.mapFactory = mapFactory;
    const crs = mapFactory === MapTypes.BMAP ? L.CRS.Baidu : L.CRS.EPSG3857;
    // 接上 Util.setOptions(this, this.options);
    // 这里直接修改options的crs属性
    this.options.crs = crs;
    // this.options.__proto__.crs = crs;
    const mapGroup = this.initTileLayer(type);
    this.baseLayerGroup && this.removeLayer(this.baseLayerGroup);
    this.baseLayerGroup = L.layerGroup(mapGroup);
    this.baseLayerGroup.addTo(this);
    this.setView(coordTransform(oldCenter, { from: oldMapFactory, to: mapFactory }));
    return this;
  }
}
class PersonalMap {
  constructor({ personal = "tencent", el, key, styleId, options = {} }) {
    if (!personal) {
      return;
    }
    this.el = el;
    let url = null;
    const map = createBaseMap(el, {
      ...options,
      minZoom: 3,
      hideMapTile: true,
      zoomAnimation: false,
    });
    if (personal === "tencent") {
      url = `https://map.qq.com/api/gljs?v=1.exp&key=${key}`;
      this._ajaxPage("tencent", url);
      this.personalMap = this._createTMap(map, styleId, el);
      this._relation(map, this.personalMap);
    }
    return map;
  }
  // 解决异步加载问题
  _ajaxPage(id, url) {
    var oXmlHttp = getHttpRequest();
    oXmlHttp.onreadystatechange = function () {
      if (oXmlHttp.readyState === 4) {
        includeJS(id, url, oXmlHttp.responseText);
      }
    };
    oXmlHttp.open("GET", url, false); // 同步操作
    oXmlHttp.send(null);
  }
  // 初始化腾讯地图
  _createTMap(map, styleId, el) {
    const elem = document.createElement("div"); // 获取tmap的dom
    const mapContaniner = document.getElementById(el); // 获取leaflet dom
    mapContaniner.style["background-color"] = "transparent"; // 设置leaflet的底图透明度
    // 保持tmap完全覆盖leaflet
    elem.id = "basicMap";
    elem.style.position = "absolute";
    elem.style.top = 0;
    elem.style.left = 0;
    elem.style.height = "100%";
    elem.style.width = "100%";
    // 添加dom并实例化tmap
    mapContaniner.appendChild(elem);
    const tMap = new TMap.Map(document.getElementById("basicMap"), {
      center: map.getCenter(),
      zoom: map.getZoom(),
      mapStyleId: styleId,
      viewMode: "2D",
    });
    return tMap;
  }
  //腾讯地图与Leaflet联动
  _relation(map, personalMap) {
    map.on("zoom", () => {
      // Tmap和Lmap的zoom联动
      personalMap.setZoom(map.getZoom());
    });
    map.on("move", () => {
      personalMap.setCenter(map.getCenter());
    });
  }
  getPersonalMap() {
    return this.personalMap;
  }
}

export { JDMap, L, PersonalMap };
