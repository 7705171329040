/*
* 监听地图上 双击事件 或 enter确认、 esc退出等
* 注意这里只负责触发once的事件，多次触发自行解绑
* */
export default function addMapEvents (el, eventList, callback, once = true) {
  if (!el || !eventList) return
  // 绑定事件监听。参数：事件、处理事件的函数，函数可以使用匿名函数，但使用匿名函数解绑不了
  function dblclick(e) {
    // console.log('你双击鼠标')
    e = e || event
    if (once) removeEvents()
    const { value, config } = getEventConfig({
      trigger: 'dblclick'
    })
    callback(value, config, e)
  }
  function mouseup (e){
    e = e || event
    // 0 左键、 1 中间键 、 2 右键
    const { value, config } = getEventConfig({
      trigger: 'mouseup',
      type: e.button
    })
    if (value !== null) {
      if (once) removeEvents()
      callback(value, config, e)
      if (e.button === 2) {
        el.oncontextmenu = preventDefault
        // 延时解绑右键菜单，因为禁用右键菜单
        setTimeout(() => {
          el.oncontextmenu = null
        })
      }
    }
  }
  function preventDefault (e) {
    e.preventDefault()
  }
  function keydown (e){
    // Enter、Esc
    e = e.originalEvent || event
    console.log(e.keyCode)
    const { value, config } = getEventConfig({
      trigger: 'keydown',
      type: e.keyCode
    })
    if(value !== null) {
      if (once) removeEvents()
      callback(value, config, e)
    }
  }
  function removeEvents () {
    // console.log('解绑map编辑监听事件')
    eventList.forEach(config => {
      if (config.trigger === 'contextmenu') {
        // 延时解绑右键菜单，因为禁用右键菜单
        setTimeout(() => {
          el.removeEventListener(config.trigger, getBindFn(config.trigger))
        }, 200)
      } else {
        el.removeEventListener(config.trigger, getBindFn(config.trigger))
      }
      if (config.trigger ==='keydown') {
        // 点完按键没在地图上操作 改为window也绑定
        window.removeEventListener(config.trigger, getBindFn(config.trigger))
      }
    })
  }
  function getBindFn (trigger) {
    let fn = null
    switch (trigger) {
      case 'dblclick':
        fn = dblclick
        break
      case 'mouseup':
        fn = mouseup
        break
      case 'keydown':
        fn = keydown
        break
    }
    return fn
  }
  function getEventConfig (rule) {
    const config = eventList.find(item => {
      const keys = Object.keys(rule)
      return keys.every(key => {
        return item[key] === rule[key]
      })
    })
    return {
      value:  config ? config.value : null,
      config: config
    }
  }
  eventList.forEach(config => {
    el.addEventListener(config.trigger, getBindFn(config.trigger))
    if (config.trigger ==='keydown') {
      // 点完按键没在地图上操作 改为window也绑定
      window.addEventListener(config.trigger, getBindFn(config.trigger))
    }
  })
  // el.$el.addEventListener('dblclick', dblclick,{ once: once })
  // el.$el.addEventListener('keydown', keydown,{ once: once })
  return removeEvents
}
